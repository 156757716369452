<script>
import SimpleTable from "../simple-table.vue";
import Avatar from "./avatar.vue";
import {mapGetters} from "vuex";

export default {
    name: "subordinates-table",

    components: {Avatar, SimpleTable},

    props: {
        year: {
            type: [String, Number],
            default: () => {
                return new Date().getFullYear();
            }
        },

        currency: {
            type: String,
            default: 'HUF'
        }
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale',
            interactions: 'performance/interactions',
            loading: 'performance/subordinatesLoading',
            authenticated: 'user/authenticated'
        }),

        users: function () {
            return this.$store.getters['performance/subordinates'](this.authenticated?.id);
        },

        fields: function () {
            return [
                {
                    key: "name",
                    label: this.$t('users.columns.name').ucFirst(),
                    thClass: ''
                },
                {
                    key: "scores",
                    label: this.$t('projects::performance.scores').ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.total?.score;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.target_score) + ' text-center';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
                {
                    key: "phones",
                    label: this.$tc('interactions::interactions.interactions.phone', 2).ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.phone']?.count;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.phone']?.target_value) + ' text-center';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
                {
                    key: "meetings",
                    label: this.$tc('interactions::meetings.meeting', 2).ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.meeting']?.count;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.meeting']?.target_value) + ' text-center';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
                {
                    key: "offer_summary",
                    label: this.$tc('projects::offers.offer', 2).ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.offer']?.amount;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.offer']?.target_value) + ' text-end';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
                {
                    key: "contract_summary",
                    label: this.$tc('projects::contracts.contract', 2).ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.contract']?.amount;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.contract']?.target_value) + ' text-end';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
                {
                    key: "documents",
                    label: this.$tc('projects::documents.document', 2).ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.document']?.count;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.document']?.target_value) + ' text-center';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
                {
                    key: "implements",
                    label: this.$tc('projects::implements.implement', 2).ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.implement']?.count;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.implement']?.target_value) + ' text-center';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
                {
                    key: "invoices",
                    label: this.$tc('invoices::invoices.invoice', 2).ucFirst(),
                    formatter: (value, index, item) => {
                        return item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.invoicing']?.count;
                    },
                    tdClass: (value, index, item, formatted) => {
                        return this.bg(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.invoicing']?.target_value) + ' text-center';
                    },
                    thClass: 'text-center text-primary bg-soft-primary font-size-18 opacity-75'
                },
            ];
        },
    },

    methods: {
        bg: function (value, target = 0) {
            if (value < target * 0.5) {
                return 'bg-soft-danger font-weight-bold';
            } else if(value < target && value >= target *0.5) {
                return 'bg-soft-warning font-weight-bold';
            } else if (value >= target) {
                return 'bg-soft-success font-weight-bold';
            }

            return '';
        },

        text: function (value, target) {
            return {
                'text-danger': target && (value < target * 0.5),
                'text-warning': value >= target * 0.5 && value < target,
                'text-success': value >= target,
            };
        }
    },
}
</script>

<template>
    <div class="subordinates-table">
        <b-overlay :show="loading">
            <simple-table :items="users" :fields="fields">
                <template #scores_header="{value}">
                    <i :title="value" class="fas fa-check-double"></i>
                </template>
                <template #phones_header="{value}">
                    <i :title="value" class="fas fa-phone-alt"></i>
                </template>
                <template #meetings_header="{value}">
                    <i :title="value" class="fas fa-calendar-alt"></i>
                </template>
                <template #offer_summary_header="{value}">
                    <i :title="value" class="fas fa-file-invoice"></i>
                </template>
                <template #contract_summary_header="{value}">
                    <i :title="value" class="fas fa-file-contract"></i>
                </template>
                <template #implements_header="{value}">
                    <i :title="value" class="fas fa-clipboard-check"></i>
                </template>
                <template #documents_header="{value}">
                    <i :title="value" class="fas fa-file-upload"></i>
                </template>
                <template #invoices_header="{value}">
                    <i :title="value" class="fas fa-file-invoice-dollar"></i>
                </template>
                <template #name="{ value, item }">
                    <router-link :to="$t('routes./profiles/:id(\\d+)', {id: item?.id}).rmValidation()">
                        <div class="d-flex align-items-center">
                            <div class="avatar-group-item me-2">
                                <avatar :user="item" hover>
                                    <template #hover>
                                        <div
                                            v-if="item?.icon"
                                            class="avatar-hover d-none avatar-title rounded-circle text-white font-size-16 user-select-none opacity-75"
                                            :style="{background: item?.color}"
                                        >
                                            <i :class="item.icon"></i>
                                        </div>
                                    </template>
                                </avatar>
                            </div>
                            <strong class="text-dark">{{ value }}</strong>
                        </div>
                    </router-link>
                </template>
                <template #scores="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.target_score" :class="text(formatted, item?.yearly?.[this.year]?.target_score)">{{ formatted }}</span>
                </template>
                <template #phones="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.phone']?.target_value"
                          :class="text(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.phone']?.target_value)"
                    >{{ formatted }}</span>
                </template>
                <template #meetings="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.meeting']?.target_value"
                          :class="text(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.meeting']?.target_value)"
                    >{{ formatted }}</span>
                </template>
                <template #implements="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.implement']?.target_value"
                          :class="text(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.implement']?.target_value)"
                      >{{ formatted }}</span>
                </template>
                <template #documents="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.document']?.target_value"
                          :class="text(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.document']?.target_value)"
                      >{{ formatted }}</span>
                </template>
                <template #offer_summary="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.offer']?.target_value?.printCurrency(currency, locale)"
                          :class="text(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.offer']?.target_value)"
                    >{{ formatted?.printCurrency(currency, locale) }}</span>
                </template>
                <template #contract_summary="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.contract']?.target_value?.printCurrency(currency, locale)"
                          :class="text(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.contract']?.target_value)"
                      >{{ formatted?.printCurrency(currency, locale) }}</span>
                </template>
                <template #invoices="{formatted, item}">
                    <span :title="item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.invoicing']?.target_value"
                          :class="text(formatted, item?.yearly?.[this.year]?.interactions?.['interactions::interactions.interactions.invoicing']?.target_value)"
                    >{{ formatted }}</span>
                </template>
            </simple-table>
        </b-overlay>
    </div>
</template>

<style scoped>

</style>
