<script>
export default {
    name: "exports-card",

    methods: {
        fetch: function () {
            this.axios.get(`performances/export/subordinates`, {responseType: 'blob'}).then(response => {
                const url = URL.createObjectURL(new Blob([response.data], {type: response.headers['content-type']}));
                const a = document.createElement('a')
                a.href = url
                const date = new Date();
                a.download = this.$t('projects::exports.projects_progress.name') + '_' + date.getFullYear() + '_' + date.getMonth() + '_' + date.getDay() + '.xlsx'
                a.click()
            })
        },
    },
}
</script>

<template>
    <div class="card">
        <div class="card-header">
            <h5 class="card-title">{{ $t('projects::exports.reports').ucFirst() }}</h5>
        </div>
        <div class="card-body bg-light">
            <b-button @click="fetch" :title="$t('projects::exports.projects_progress.name').ucFirst()" variant="outline-success">
                <i class="fas fa-file-excel font-size-24"></i>
            </b-button>
        </div>
    </div>
</template>

<style scoped>

</style>
