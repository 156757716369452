<script>
import Avatar from "./avatar.vue";
import {mapGetters} from "vuex";

export default {
    name: "subordinates-performance-card",

    components: {Avatar},

    props: {
        user: {
            type: Object,
            required: true
        },

        year: {
            type: [String, Number],
            default: () => {
                return new Date().getFullYear();
            }
        },

        month: {
            type: [String, Number],
            default: () => {
                return new Date().getMonth() + 1;
            }
        }
    },

    computed: {
        ...mapGetters({
            locale: 'trans/locale'
        }),

        statistics: function () {
            return this.user?.monthly?.[this.year]?.[this.month] || {};
        },
    },

    methods: {
        color: function (value = 0, target) {
            if (value >= target) {
                return 'success';
            } else if (value > target * 0.75 && value < target * 0.9) {
                return 'warning';
            } else if (value <= target * 0.75) {
                return 'danger';
            }

            return 'primary';
        },
    },
}
</script>

<template>
    <div class="card">
        <div class="card-header bg-soft-primary">
            <div class="card-title">
                <div class="d-flex justify-content-center align-items-center">
                    <div class="d-flex flex-column">
                        <avatar :user="user" size="md"></avatar>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="mx-4">
                            <i class="fas fa-calendar-alt font-size-32 text-muted opacity-50"></i>
                        </div>
                        <div>
                            <p class="mb-0">
                                <small>{{ year }}.</small>
                            </p>
                            <h5 class="mb-0">{{ month.printMonth() }}</h5>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
            <table class="table table-sm align-middle table-striped table-hover table-nowrap mb-0">
                <tbody>
                <tr>
                    <td>
                        <span class="font-size-12 d-inline-flex align-items-center justify-content-center text-center border border-white rounded bg-gradient cube-24px"
                              :class="'text-' + color(statistics?.total?.score, statistics?.target_score) + ' bg-soft-' + color(statistics?.total?.score, statistics?.target_score)"
                              :title="$t('projects::performance.scores').ucFirst()"
                        >
                            <i class="fas fa-check-double"></i>
                        </span>
                    </td>
                    <td>
                        <span class="font-size-16 font-weight-bold" :class="'text-' + color(statistics?.total?.score, statistics?.target_score)">{{ statistics?.total?.score }}</span>
                    </td>
                    <td class="text-end">
                        <span :title="$tc('projects::target_values.monthly_target_value', 1).ucFirst()"
                              class="bull-eye ms-2"
                              :class="'bull-eye-' + color(statistics?.total?.score, statistics?.target_score)"
                        >
                            {{ statistics?.target_score }}
                        </span>
                    </td>
                </tr>
                <template v-for="(interaction, key) in statistics?.interactions" :key="key">
                    <tr>
                        <td>
                            <span class="font-size-12 d-inline-flex align-items-center justify-content-center text-center border border-white rounded bg-gradient cube-24px"
                                  :class="'text-' + color(interaction?.value, interaction?.target_value) + ' bg-soft-' + color(interaction?.value, interaction?.target_value)"
                                  :title="$t(interaction.translation_key).ucFirst()"
                            >
                                <i :class="interaction?.icon"></i>
                            </span>
                            </td>
                        <td>
                            <span class="font-size-16 font-weight-bold" :class="'text-' + color(interaction?.value, interaction?.target_value)">{{ interaction?.is_financial ? (interaction?.value?.printCurrency(currency, locale) || 0) : interaction?.value }}</span>
                        </td>
                        <td class="text-end">
                            <span v-if="interaction?.target_value && !interaction?.is_financial" :title="$tc('projects::target_values.monthly_target_value', 1).ucFirst()"
                                  class="bull-eye ms-2"
                                  :class="'bull-eye-' + color(interaction?.value, interaction?.target_value)"
                            >
                                {{ interaction?.target_value }}
                            </span>
                            <small class="font-weight-bold text-muted" v-else-if="interaction?.target_value && interaction?.is_financial">
                                {{ interaction?.target_value?.printCurrency(currency, locale) || 0 }}
                            </small>
                        </td>
                    </tr>
                </template>
                </tbody>
            </table>
        </div>
    </div>
</template>

<style scoped>
.cube-40px {
    width: 40px;
    height: 40px;
}

.cube-20px {
    width: 20px;
    height: 20px;
}

.cube-24px {
    width: 24px;
    height: 24px;
}
</style>
